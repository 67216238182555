
import React, { useState, useEffect } from 'react';

import logo from "../../assets/imgs/icons/logo.svg";
import biglogo from "../../assets/imgs/icons/big_logo.png";
import './nav-bar.scss';

const NavBar = ({activeLink, handleNavItemClick }) => {
  const [navBarStyle, setNavBarStyle] = useState('navbar');

  useEffect(() => {
    if(activeLink === 'Main'){
      setNavBarStyle('navbar');
    } else if(activeLink === 'History'){
      setNavBarStyle('navbar history-style');
    } else if(activeLink === 'Team'){
      setNavBarStyle('team-style');
    }
  }, [activeLink]);

  return (
    <nav className={navBarStyle}>
      <div className="navbar__wrapper">
        <div className="navbar__logo" onClick={() => handleNavItemClick("Main")}>
          <img src={activeLink === 'Main' ? logo : biglogo} alt="main logo" />
        </div>
        <ul className="navbar__links">
          <li>
            <div className='navbar__links__item' onClick={() => handleNavItemClick("History")}>01. HISTORY</div>
          </li>
          <li>
            <div className='navbar__links__item' onClick={() => handleNavItemClick("Team")}>02. TEAM</div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;