
import React from "react";
import Slider from "react-slick";

import img1 from "../../assets/imgs/img/carousel1.jpg";
import img2 from "../../assets/imgs/img/carousel2.jpg";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import './slider.scss';

const Carousel = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false
        }
      }
    ]
  };

  const images = [
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
  ];

return (
  <div className="slider">
    <div className="slider__wrapper">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`slide ${index+1}`} />
          </div>
        ))}
      </Slider>
    </div>
  </div>
  );
};

export default Carousel;