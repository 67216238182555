
import './main.scss';

const Main = () => {
  return (
    <section className='main'>
      
    </section>
  );
};

export default Main;