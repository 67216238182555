
import React, { useState } from 'react';
import Footer from "../../components/footer/footer";
import chapter2 from "../../assets/imgs/img/02climb.png";
import shedule from "../../assets/imgs/img/schedule.png";

import './team.scss';

const SheduleItem = ({ date, comment }) => (
  <div className="team__shedule__item">
    <div className="team__shedule__date">{date}</div>
    <div className="team__shedule__coment">{comment}</div>
  </div>
);

const Team = ({activeLink, handleNavItemClick }) => {
  const [activeTab, setActiveTab] = useState("MOUNTAIN 1");

  const dates = activeTab === 'MOUNTAIN 1' 
    ? ['25 Nov 2016', '28 Nov 2016', '18 Dec 2016', '7 Jan 2017']
    : ['17 Nov 2016', '13 Dec 2016', '28 Dec 2016', '9 Feb 2017'];

  const activeComponent = (
    <div className="team__tabs__content">
      <img src={shedule} alt="shedule" />
      <div className="team__shedule__text">
        {dates.map(date => (
          <SheduleItem date={date} comment="Vestibulum viverra" />
        ))}
      </div>
    </div>
  );

  return (
    <section className='team'>
      <div className="team__header">
        <div className="team__header__img">
          <img src={chapter2} alt="climb 2" />
        </div>
        <div className="team__header__text">
          Cras scelerisque id quam sed dignissim Pellentesque urna nunc, gravida quis hendrerit ac, tristique ut quam. Vivamus suscipit dignissim tortor nec congue. 
        </div>
      </div>
      <div className={activeTab === "MOUNTAIN 1" ? "team__tabs" : "team__tabs team__tabs_2"} >
        <div className="team__tabs__header">
          <div
            className={activeTab === "MOUNTAIN 1" ? "team__tab_active" : "team__tab"}
            onClick={() => setActiveTab("MOUNTAIN 1")}
          >
            <p>MOUNTAIN 1</p>  
          </div>
          <div 
            className={activeTab === "MOUNTAIN 2" ? "team__tab_active" : "team__tab"}
            onClick={() => setActiveTab("MOUNTAIN 2")}
          >
            <p>MOUNTAIN 2</p>  
          </div>
        </div>
        {activeComponent}
      </div>
      <Footer handleNavItemClick={handleNavItemClick} activeLink={activeLink}/>
    </section>
  );
};

export default Team;