
import darklogo from "../../assets/imgs/icons/big_logo_dark.png";

import './footer.scss';

const Footer = ({activeLink, handleNavItemClick }) => {
  return (
    <footer className='footer'>
      <div className="footer__wrapper">
        <div className="footer__logo">
          <img src={darklogo} alt="dark logo" onClick={() => handleNavItemClick("Main")}/>
        </div>
        <div className="footer__rights">COPYRIGHT 2016. ALL RIGHTS RESERVED.</div>
      </div>
    </footer>
  );
};

export default Footer;